import React from 'react';

export default function Form() {
	return (
		<>
			<form key="main_contact_form" name="contact" data-netlify="true" method="POST">
				<input type="hidden" name="form-name" value="contact" />
				<input required name="email" type="email" placeholder="Your Email" />

				<textarea required name="message" type="text" placeholder="Your Message" />
				<button type="submit" className="link">
					Send
				</button>
			</form>
		</>
	);
}
