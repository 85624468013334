import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import styled from 'styled-components';
import Template from '../layouts/Template';
import Form from '../components/Form';

const StyledContact = styled.section`
	h1 {
		${tw`mb-10`};
	}
	input,
	textarea {
		${tw`w-full bg-transparent text-white text-24 sm:text-36 mb-10 pb-3`};
		border-bottom: 1px solid #fff;
		transition: ease all 0.2s;
		&:active,
		&:focus {
			outline: 0;
			border-bottom: 1px solid #f8ff00;
		}
	}

	.error {
		border-bottom: 1px solid #dc143c !important;
	}
	.link {
		${tw`text-24 sm:text-36 text-white`};
		border-bottom: 3px solid #f8ff00;
		transition: ease all 0.2s;
		&:hover {
			${tw`bg-secondary text-primary`};
		}
	}
	.loader {
		font-size: 10px;
		margin: 50px auto;
		text-indent: -9999em;
		width: 11em;
		height: 11em;
		border-radius: 50%;
		background: #ffffff;
		background: -moz-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
		background: -webkit-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
		background: -o-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
		background: -ms-linear-gradient(left, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
		background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
		position: relative;
		-webkit-animation: load3 1.4s infinite linear;
		animation: load3 1.4s infinite linear;
		-webkit-transform: translateZ(0);
		-ms-transform: translateZ(0);
		transform: translateZ(0);
	}
	.loader:before {
		width: 50%;
		height: 50%;
		background: #ffffff;
		border-radius: 100% 0 0 0;
		position: absolute;
		top: 0;
		left: 0;
		content: '';
	}
	.loader:after {
		background: #004664;
		width: 75%;
		height: 75%;
		border-radius: 50%;
		content: '';
		margin: auto;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
	}
	@-webkit-keyframes load3 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
	@keyframes load3 {
		0% {
			-webkit-transform: rotate(0deg);
			transform: rotate(0deg);
		}
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}

	.contact-form {
		${tw`w-full sm:w-2/3 sm:pr-10 md:pr-20`};
	}
	.contact-image {
		${tw`hidden sm:flex w-full sm:w-1/3`};
		align-items: center;

		img {
			transition: ease all 0.2s;
			&:hover {
				transform: translateX(15px);
			}
		}
	}
`;

const Contact = (props) => {
	const { data } = props;
	return (
		<Template title={`Contact | ${data.site.siteMetadata.title}`} desc="Contact me here.">
			<StyledContact>
				<div className="wrapper">
					<div className="row">
						<div className="contact-form">
							<h1>Contact</h1>
							<Form />
						</div>
						<div className="contact-image">
							<img src="/mail.png" alt="mail" />
						</div>
					</div>
				</div>
			</StyledContact>
		</Template>
	);
};
Contact.propTypes = {
	data: PropTypes.object.isRequired,
};
export default Contact;

export const pageQuery = graphql`
	query ContactQuery {
		site {
			siteMetadata {
				title
			}
		}
	}
`;
